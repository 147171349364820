<template>
  <v-card-text
    class="mb-n3 ml-n3 blue-grey--text"
    style="font-size: 18px; font-weight: bold"
    >{{ text }}</v-card-text
  >
</template>

<script>
export default {
  name: 'PageTitle',
  props: ['text'],
};
</script>

<style scoped></style>
